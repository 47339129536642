.watermark-div {
    width: 100%;
    height: 100vh;
  }
  
  .watermark-text {
    font-family: "Dosis", sans-serif;
    font-weight: 800;
    font-size: 400px;
  }
  
  .watermark-firstname-location{
    position: absolute;
    top: 25%;
    left: 10%;
    transform: translate(-50%, -50%);
  }
  
  .watermark-lastname-location{
    position: absolute;
    top: 80%;
    left: 70%;
    transform: translate(-50%, -50%);
  }