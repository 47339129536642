.box {
    height: calc(100% - 20px);
    padding-inline: 20px;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    overflow: hidden;
}