.work-section {
    width: 100%;
    padding-top: 18%;
    padding-inline: 50px;
    box-sizing: border-box;

    overflow-y: hidden;
    /* background-color: aqua; */
}

.p1-div {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */

    margin-top: 100px;
    position: relative;
    /* background-color: antiquewhite; */
}

.p1-image {
    width: 70vh;
    height: 70vh;
    background-image: url('../../Assets/Projects/SpendCal/SpendCal.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    /* background-color: aquamarine; */
}

.p1-svg {
    position: absolute;
    top: -20%;
    left: -18%;

    /* background-color: beige; */
}

.p1-text {
    flex: 1;
    font-family: 'Outfit', sans-serif;
    /* background-color: aqua; */
}

.p1-title {
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 600;
}

.p1-description {
    width: 80%;
    text-align: justify;
}

.p2-div {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;

    margin-top: 200px;
    position: relative;
    /* background-color: antiquewhite; */
}

.p2-image {
    width: 70vh;
    height: 70vh;
    background-image: url('../../Assets/Projects/TravelCompanion/TravelCompanion.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.p2-svg {
    position: absolute;
    top: -30%;
    right: -15%;
}

.p2-text {
    flex: 1;
    font-family: 'Outfit', sans-serif;

    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
}

.p2-title {
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 600;
}

.p2-description {
    width: 80%;
    text-align: justify;
}

.p3-div {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */

    margin-top: 100px;
    position: relative;
    /* background-color: antiquewhite; */
}

.p3-image {
    width: 80vh;
    height: 70vh;
    background-image: url('../../Assets/Projects/JobPower/JobPower.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    /* background-color: aquamarine; */
}

.p3-svg {
    position: absolute;
    top: -28%;
    left: -12%;

    /* background-color: beige; */
}

.p3-text {
    flex: 1;
    font-family: 'Outfit', sans-serif;
    /* background-color: aqua; */
}

.p3-title {
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 600;
}

.p3-description {
    width: 80%;
    text-align: justify;
}
