.greeting {
    font-size: 46px;
    font-family: "Raleway", sans-serif;
    font-weight: 800;
    overflow: hidden;
}

/* .background-div:hover {
    height: 0vw;
    width: 0%;
    border-radius: 100%;
} */