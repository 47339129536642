.parent {
    margin-top: 20px;
    /* width: 615px; */
    width: 480px;
    height: 60px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    overflow: hidden;
    z-index: 10;
}

.parent[data-inTouchVisible="false"] {
    margin-top: 20px;
    /* width: 480px; */
    width: 330px;
    height: 60px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    overflow: hidden;
}

.parent[data-isPlaying="true"] {
    margin-top: 5vh;
    width: calc(100vw - 10vh);
    height: 90vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    overflow: hidden;

    box-shadow: 0px 0px 30px 10px #f7f7ff07;
}

.parent[data-isOpen="true"] {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: 0;
}

.contact-container {
    height: 60px;
    margin-top: 4.5%;
}

.demo-div {
    width: 100%;
    height: 100%;
    border-radius: 50px;

    position: relative;
    box-sizing: border-box;
}

.demo-title-div {
    position: absolute;
    top: 5%;
    left: 0%;

    width: calc(100% - 100px);
    margin-inline: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    z-index: 1;
}

.demo-title{
    font-size: 24px;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
}

.demo-close{
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    cursor: pointer;
}

.demo-close:hover{
    text-decoration: underline;
    font-weight: 600;    
}

.demo{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}