@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap');

.Work-Banner-main-div{
    width: 100vw;
    box-sizing: border-box;
}

.parallax{
    padding-block: 15px;
}

.scroller{
    overflow: hidden;
    width: 180%;
}

.scroller span{
    font-family: "Dosis";
    font-weight: 500;
    font-size: 22px;
    margin-inline: 30px;
}