.spendCal-play-button {
    position: absolute;
    top: 36%;
    right: 20%;
    cursor: pointer;

    width: 50px;
    height: 50px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.travelCompanion-play-button {
    position: absolute;
    top: 43%;
    left: 18%;
    cursor: pointer;

    width: 50px;
    height: 50px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.jobPower-play-button{
    position: absolute;
    top: 42%;
    right: 18%;
    cursor: pointer;

    width: 50px;
    height: 50px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}