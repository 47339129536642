.my-name {
  position: relative;
  top: 30%;
  left: 18%;
  font-family: 'Outfit', sans-serif;
  font-weight: 200;
  font-size: 28px;
}

.my-role {
  position: relative;
  top: 31%;
  left: 18%;
  font-family: 'Outfit', sans-serif;
  font-weight: 800;
  font-size: 20px;
}

.my-dis {
  position: relative;
  top: 34%;
  left: 18%;
  font-family: 'Outfit', sans-serif;
  font-weight: 200;
  font-size: 16px;
}

.contact-div {
  position: relative;
  top: 40%;
  left: 18%;
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 16px;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  cursor: pointer;
}

.contact-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  padding: 7px;
}

.world-icon {
  width: 100%;
  height: 100%;
  background-image: url('../../Assets/Icons/world_dark.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}