/* Fase In amination */
.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
    overflow: hidden;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* --end-- */

.main {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.no-select {
    -webkit-user-select: none; /* For Safari */
    -moz-user-select: none;    /* For Firefox */
    -ms-user-select: none;     /* For IE/Edge */
    user-select: none;         /* Standard */
  }